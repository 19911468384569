import React, { useState, useEffect } from 'react';
import {useDropzone} from 'react-dropzone';
import downloadIcon from '../css/icons/icon-upload.png';
import fileIcon from '../css/icons/icon-file.png';
import loadingIcon from '../css/icons/icon-loading.gif';
import getUserData from '../xcl_modules/userData';
import getTokenData from '../xcl_modules/tokenData';
import dropzoneStyle from '../xcl_modules/dropzoneStyle';
import Swal from 'sweetalert2';

const downloadIconAlt = "upload file icon";
const fileIconAlt = "file icon";
const loadingIconAlt = "loading icon";

function DropzoneContent(props) {

  const [uploadedFileSrc, setUploadedFileSrc] = useState( props.contenturl );
  const [uploadedFileName, setUploadedFileName] = useState( props.filename );
  const [uploadedFileType, setUploadedFileType] = useState( props.filetype );
  const [uploadedFileTypeReadable, setUploadedFileTypeReadable] = useState( "" );
  const [uploadInProgress, setUploadState] = useState( false );
  const [uploadErrors, setUploadErrors] = useState( [] );
  const [uploadprogress, setUploadprogress] = useState( 0 );

  //const acceptedFileTypes = ['image/png','image/jpeg','image/jpg','application/zip','application/x-zip-compressed','application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.ms-powerpoint','application/msword','application/vnd.ms-excel','audio/mpeg','video/mp4','application/pdf'];
  const acceptedFileTypes = ['image/png','image/jpeg','image/jpg','application/zip','application/x-zip-compressed','application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.ms-powerpoint','application/msword','application/vnd.ms-excel','video/mp4','application/pdf'];
  //const types = ['png','jpeg','jpg','zip','zip','pptx','docx','xlsx','ppt','doc','xls','mpeg','mp4','pdf'];
  const types = ['png','jpeg','jpg','zip','zip','pptx','docx','xlsx','ppt','doc','xls','mp4','pdf'];

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    uploadMulitple: false,
    maxFiles: 1,
    name: "logo_uploaded",
    noKeyboard: true,
    onDrop: (acceptedFiles, fileRejections) => {
    
      //const acceptedFileTypes = ['image/png','image/jpeg','image/jpg','application/zip','application/x-zip-compressed','application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.ms-powerpoint','application/msword','application/vnd.ms-excel','audio/mpeg','video/mp4','application/pdf'];
          
      if (fileRejections.length>0) {
        Swal.fire({
          position: "top",
          iconHtml:  '<div class="correctitoast"></div>',
          title: "Please limit upload to a single file.",
          showConfirmButton: false,
          timer: 2000,
          toast: true,
          timerProgressBar: true,
          showClass: {
              popup: 'toastWarningOn'
            },
          hideClass: {
              popup: 'toastWarningOff'
          }
        });
      } else {
        //console.log("start file read");
        setUploadState(true);
        acceptedFiles.forEach((file) => {
          const reader = new FileReader();
    
          reader.onabort = () => console.log('file reading was aborted');
          reader.onerror = (e) => {
            setUploadState(false);
            Swal.fire({
              position: "top",
              iconHtml:  '<div class="correctitoast"></div>',
              title: "Please select a different file, this one cannot be uploaded.",
              showConfirmButton: false,
              timer: 2000,
              toast: true,
              timerProgressBar: true,
              showClass: {
                  popup: 'toastWarningOn'
                },
              hideClass: {
                  popup: 'toastWarningOff'
              }
            });
          }
          
          reader.onload = async () => {

            // test the size, file.size is in KB (divide the props.storageAvailble by 1024)
            const storageAvail = (props.storageAvailable);

            //console.log(file.size + " limit:" + 2 * 1024 * 1024 * 1024);

            if (file.size > 2 * 1024 * 1024 * 1024) {
              setUploadState(false);
              Swal.fire({
                position: "top",
                iconHtml:  '<div class="correctitoast"></div>',
                title: "Please select a different file, you don't have enough remaining storage for that one.",
                showConfirmButton: false,
                timer: 2000,
                toast: true,
                timerProgressBar: true,
                showClass: {
                    popup: 'toastWarningOn'
                  },
                hideClass: {
                    popup: 'toastWarningOff'
                }
              });
      
            } else if (file.size > storageAvail) {
              setUploadState(false);
              Swal.fire({
                position: "top",
                iconHtml:  '<div class="correctitoast"></div>',
                title: "Please select a different file, you don't have enough remaining storage for that one.",
                showConfirmButton: false,
                timer: 2000,
                toast: true,
                timerProgressBar: true,
                showClass: {
                    popup: 'toastWarningOn'
                  },
                hideClass: {
                    popup: 'toastWarningOff'
                }
              });
      
            } else {

              // Do whatever you want with the file contents
              if (acceptedFileTypes.indexOf(file.type) > -1) {
                // do a thing with a thing or two
                const userData = getUserData();
                const tokenData = getTokenData();
    
                // create a sudo form
                const formData = new FormData();
                formData.append("accountID", userData.accountID);
                formData.append("linkID", props.linkID);
                const loc = acceptedFileTypes.indexOf(file.type);
                formData.append("ft", types[loc]);
                formData.append("ftu", file);
    
                const xhr = new XMLHttpRequest();
                const success = await new Promise((resolve) => {
                  xhr.upload.addEventListener("progress", (event) => {
                    if (event.lengthComputable) {
                      //console.log("upload progress:", event.loaded / event.total);
                      setUploadprogress(Math.round((event.loaded / event.total) * 1000) / 10);
                    }
                  });
                  xhr.addEventListener("loadend", () => {
                    resolve(true);
                  });
                  xhr.open("POST", process.env.REACT_APP_BE+'/uploadcontent', true);
                  xhr.setRequestHeader("Authorization", `Bearer ${tokenData.accessToken}`);
                  xhr.send(formData);
                  xhr.onreadystatechange = function() {
                    if (this.readyState === 4 && this.status === 200) {
                      //console.log("STATE CHANGED");
                      //console.log(this.responseText);
                      const data = JSON.parse(this.responseText);
                      //console.log(data);
                      
                      if (data.errors.length > 0) {
                        data.errors.push("Upload completed with errors. Your link may not work!");
                      }
  
                      setUploadErrors(data.errors);
  
                      setUploadedFileName(file.name);
                      setUploadedFileType(data.filetype);
                      
                      setUploadedFileSrc(data.pathToFile);
                      setUploadedFileTypeReadable(data.filetype);
  
                      setUploadState(false);
  
                      props.onChange('contenturl', data.pathToFile, 'filetype', data.filetype, data.objectid);
                    }
                  };
                });
                console.log("upload success:", success);

              } else {
                setUploadState(false);          
                Swal.fire({
                  position: "top",
                  iconHtml:  '<div class="correctitoast"></div>',
                  //title: "Please limit uploads to ZIP, PPTX, DOC, XLSX, MP4, PDF, PNG, or JPG files, you attempted to upload ("+file.type+").",
                  title: "Please limit uploads to zip (xAPI or SCORM 2004), pptx, doc, xlsx, mp4, png, jpg, or pdf files.",
                  showConfirmButton: false,
                  timer: 3000,
                  toast: true,
                  timerProgressBar: true,
                  showClass: {
                      popup: 'toastWarningOn'
                    },
                  hideClass: {
                      popup: 'toastWarningOff'
                  }
                });
  
              }
            }
          }
          reader.readAsArrayBuffer(file);
          //console.log("end file read");
        })
      }
    }
  });

  const dzButton = {
      color: "#513AD1",
      backgroundColor: "transparent",
      padding: "0px",
      textDecorationLine: "underline",
      border: "none"
  };

  const dlIcon = {
    display: "inline-block",
    margin: "25px auto 0px auto"
}

const dlSpinner = {
  display: "inline-block",
  margin: "25px auto 0px auto",
  height: "20px"
}

useEffect(() => {
    
    if (uploadedFileType!=="") {
        //const mimeTypes = ['image/png','image/jpeg','image/jpg','application/zip','application/x-zip-compressed','application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.ms-powerpoint','application/msword','application/vnd.ms-excel','audio/mpeg','video/mp4','application/pdf'];
        const mimeTypes = ['image/png','image/jpeg','image/jpg','application/zip','application/x-zip-compressed','application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.ms-powerpoint','application/msword','application/vnd.ms-excel','video/mp4','application/pdf'];
        //const types = ['png','jpeg','jpg','zip','zip','pptx','docx','xlsx','ppt','doc','xls','mpeg','mp4','pdf'];
        const types = ['png','jpeg','jpg','zip','zip','pptx','docx','xlsx','ppt','doc','xls','mp4','pdf'];

        let readAbleType = "";
        if (uploadedFileType.length > 6) {
          readAbleType = types[mimeTypes.indexOf(uploadedFileType)];
        } else {
          readAbleType = uploadedFileType;
        }
    
        setUploadedFileTypeReadable(readAbleType);
    }

  },[uploadedFileType]);


  const doChangeFile = () => {
    setUploadedFileSrc("");
    setUploadedFileType("");
    setUploadedFileTypeReadable("");
    props.onChange('contenturl', '', 'filetype', ''); // needs to be a space bro, or the component will unload (adminsettings.js rule)
  }

  return (
    <div className="container">
      <style>{dropzoneStyle}</style>
      <label htmlFor='logo_uploaded'>Upload a File</label>
      <p>
      File types: zip (xAPI or SCORM 2004), pptx, doc, xlsx, mp4, png, jpg, or pdf<br/>
      Storage Available: {Math.round(props.storageAvailable/1048576)} MB<br/>
      XCL cannot accept files larger than 2 GB.<br/>
      <span style={{ fontWeight: "700", fontSize: "smaller" }}>For training modules: xAPI and SCORM 2004 packaged modules as zip files. <br/>
      Please only upload single modules. XCL does not provide a menu for multi-module packages.</span><br/>
      </p>
      {
        (uploadedFileSrc) ? (
          <>
            <div className="dropzone-errors">
              <ul>
                { uploadErrors.map((error, i) => (
                  <li key={"err" + i}>{error}</li>
                )) }
              </ul>
            </div>
            <div className="dropzone">
                <img src={fileIcon} alt={fileIconAlt} style={dlIcon} /><br/>
                <div className='fileNameBadge' title={`${uploadedFileTypeReadable} file type`} >{uploadedFileTypeReadable}</div>
                <div>{uploadedFileName || props.filename || ""}</div>
            </div>
            <input type="button" className="changeImageButton" href='#' onClick={doChangeFile} value="Change File"></input>
          </>
        ) : 
           (uploadInProgress) ? (
            <>
              <div className="dropzone">
                  <img src={loadingIcon} alt={loadingIconAlt} style={dlSpinner} />
                  <div style={{ width:"100px", height: "10px", border: "1px solid #333", margin: "4px 10px", display: "inline-block" }}>
                    <div style={{ height:"10px", backgroundColor: "#333", width: `${uploadprogress}px` }}></div>
                  </div>
                  <div>Progress {uploadprogress}%<br/>Do not leave this screen.</div>
              </div>
            </>
            ) : (
            <>
              <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <img src={downloadIcon} alt={downloadIconAlt} style={dlIcon} />
                <br/>
                Drag 'n' drop file here<br/>
                or<br/>
                <button type="button" style={dzButton} onClick={open}>
                  Choose file
                </button>
              </div>
            </>
          )}
          
      
    </div>
  );
}

export default DropzoneContent;