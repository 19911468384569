import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from "react-router-dom";
import iconModalConfirm from '../css/icons/icon-modal-confirm.png';
import iconModalVerify from '../css/icons/icon-warning-bell.png';
import iconModalSuccess from '../css/icons/icon-announce-success.png';
import { useState, useRef } from 'react';
import getUserData from '../xcl_modules/userData';
import getTokenData from '../xcl_modules/tokenData';

const ConfirmDeleteAccount = (props) => {
    const [codeInputs, setCodeInputs] = useState( { char1: '', char2: '', char3: '', char4: '', char5: '', char6: '' } );
    const char1 = useRef( );
    const char2 = useRef( );
    const char3 = useRef( );
    const char4 = useRef( );
    const char5 = useRef( );
    const char6 = useRef( );
    const [first, setFirst] = useState( false );
    const [second, setSecond] = useState( false );
    const [third, setThird] = useState( false );
    const [confirmation, setConfirmation] = useState( true );
    const [validation, setValidation] = useState( false );
    const [postValidation, setPostValidation] = useState( false );
    const [valid, setValid] = useState( false );
    const [vcode, setVcode] = useState( '' );

    const userData = getUserData();
    const youremail = (userData.email) ? userData.email : (userData.emailaddress) ? userData.emailaddress : userData.username;

    const doSetFirstChallenge = (event) => {
        setFirst((event.target.checked) ? true : false);
    }
    
    const doSetSecondChallenge = (event) => {
        setSecond((event.target.checked)? true : false);
    }
    
    const doSetThirdChallenge = (event) => {
        setThird((event.target.checked)? true : false);
    }

    const doValidate = async () => {

        setConfirmation( false );
        setValidation( true );

        try {
            const response = await fetch(process.env.REACT_APP_BE+`/account/validate`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 
                  Authorization: `Bearer ${getTokenData().accessToken}`},
                body: JSON.stringify( { accountID: userData.accountID } )
              });
          
              const result = await response.json();
          
              if (result.status === "success") {
                setVcode(result.code);
              }
        } catch (err) {
            console.log("problem here ("+ err+ ")");
        }
    }

    const doCodeInput = (event) => {
        // 1 get code input and update inputs
        const chars = [char1, char2, char3, char4, char5, char6];
        const name = event.target.name;
        const value = event.target.value;
        setCodeInputs(values => ({...values, [name]: value}));

        chars.forEach((char, position) => {
            if (char.current.name===name && position < 5) {
                chars[position+1].current.select();
                chars[position+1].current.focus();
            }
        });

        
        /*
        // an attempt to allow paste on the first character, didn't happen quickly.

        if (name === 'char1') {

            console.log(event.target.value);
            // 2 if there is a big code pasted in the first box, please distribute to other code boxes
            const letters = event.target.value.split("");
            letters.forEach((letter, position) => {
                console.log(position + ": " + letter);
                const value = letter;
                setCodeInputs(values => ({...values, [chars[position]]: value}));
            });

        } else {

            const value = (event.target.value!=="") ? event.target.value.substring(0,1) : "";
            setCodeInputs(values => ({...values, [name]: value}));

        }

        let newCode = "";
        chars.forEach((key) => {
            newCode += codeInputs[key];
        })
        setVcodeEntered(newCode);
        */
        
    }

    const isValid = () => {
        let valid = true;
        let vcodeEntered = "";
        Object.entries(codeInputs).forEach(([key, val]) => {
            vcodeEntered += val;
            if (val==='') {
                valid = false;
            }
        });
        if (valid) {
            if (vcodeEntered === vcode.toString()) {
                setValid( true );
            } else {
                setValid( false );
            }
        } else {
            setValid( false );
        }
    }

    const doDelete = () => {
        if (first && second && third && valid) {
            setValidation( false );
            setPostValidation( true );
            props.onDelete();
        }
    }

    return (
        <Popup open={props.open} closeOnDocumentClick onClose={props.onClose} className='xclPop'>
            <form style={ (confirmation) ? {  } : { display: "none" } }>
                <div className='confirmIcon' style={{ backgroundImage: `url('${iconModalConfirm}')` }}></div>
                <div className='modalContent'>
                    <h2 style={{ lineHeight: "110%", marginTop: "12px" }}>Please confirm you understand the following 
                    before your account can be cancelled.</h2>
                    <input type="checkbox" name='first' onChange={doSetFirstChallenge} checked={first || ''} style={{ float: "left", width: "20px", marginRight: "8px" }} />
                    <label htmlFor='first' style={{ fontSize: "1rem" }}>I understand all branding material and Learning Links will be 
                    permanently deleted from my account.</label>
                    <input type="checkbox" name='second' onChange={doSetSecondChallenge} checked={second || ''} style={{ float: "left", width: "20px", marginRight: "8px" }} />
                    <label htmlFor='second' style={{ fontSize: "1rem" }}>I understand that any Learning Links in this account will become 
                    inaccessible.</label>
                    <input type="checkbox" name='third' onChange={doSetThirdChallenge} checked={third || ''} style={{ float: "left", width: "20px", marginRight: "8px" }} />
                    <label htmlFor='third' style={{ fontSize: "1rem" }}>I understand that this account will be permanently deleted and 
                    cannot be recovered.</label>
                </div>            
                <div className='controls' style={{ marginTop: "16px" }}>
                    <Link className='cancelBtn' onClick={props.onClose}>Cancel</Link>
                    <Link className={ (first && second && third) ? 'confirmBtn' : 'confirmBtn disabled' } onClick={doValidate}>Continue</Link>
                </div>
            </form>
            <form style={ (validation) ? {  } : { display: "none" } }>
                <div className='confirmIcon' style={{ backgroundImage: `url('${iconModalVerify}')` }}></div>
                <div className='modalContent'>
                    <h2 style={{ lineHeight: "110%", marginTop: "12px" }}>Verify and cancel your account.</h2>
                    <p>We have sent a 6 digit verification code to <strong>{youremail}</strong>. Once you enter the code 
                        below we will complete the cancellation of your account.</p>
                    <div className='validationInputContainer'>
                        <input 
                            type='text'
                            name='char1'
                            ref={char1}
                            maxLength={1}
                            value={codeInputs.char1}
                            onChange={doCodeInput} onKeyUp={isValid}
                        />
                        <input 
                            type='text'
                            name='char2'
                            ref={char2}
                            maxLength={1}
                            value={codeInputs.char2}
                            onChange={doCodeInput} onKeyUp={isValid}
                        />
                        <input 
                            type='text'
                            name='char3'
                            ref={char3}
                            maxLength={1}
                            value={codeInputs.char3}
                            onChange={doCodeInput} onKeyUp={isValid}
                        />
                        <div className='validationCodeDivider'></div>
                        <input 
                            type='text'
                            name='char4'
                            ref={char4}
                            maxLength={1}
                            value={codeInputs.char4}
                            onChange={doCodeInput} onKeyUp={isValid}
                        />
                        <input 
                            type='text'
                            name='char5'
                            ref={char5}
                            maxLength={1}
                            value={codeInputs.char5}
                            onChange={doCodeInput} onKeyUp={isValid}
                        />
                        <input 
                            type='text'
                            name='char6'
                            ref={char6}
                            maxLength={1}
                            value={codeInputs.char6}
                            onChange={doCodeInput} onKeyUp={isValid}
                        />
                    </div>
                </div>            
                <div className='controls' style={{ marginTop: "16px" }}>
                    <Link className='cancelBtn' onClick={props.onClose}>Cancel</Link>
                    <Link className={ (valid) ? 'confirmDeleteBtn' : 'confirmDeleteBtn disabled' } onClick={doDelete}>Cancel Account</Link>
                </div>
            </form>
            <div style={ (postValidation) ? {  } : { display: "none" } }>
                <div className='successIcon' style={{ backgroundImage: `url('${iconModalSuccess}')` }}></div>
                <div className='modalContent success'>
                    <h2 className='success'>Your Account will be cancelled at the end of your current subscription period</h2>
                </div>            
                <div className='controls'>
                    <Link className='closeBtn' onClick={props.onClose}>X Close</Link>
                </div>
            </div>
        </Popup>
    );


}

export default ConfirmDeleteAccount;