export const NavbarData = [
  {
    id: "home",
    title: "Home",
    path: "/xcl/",
    icon: 'icon-home-wire icons-menu',
    cName: "nav-text",
    public: true
  },
  {
    id: "profiles",
    title: "Brand Templates",
    path: "/xcl/profiles",
    icon: 'icon-horn-wire icons-menu',
    cName: "nav-text",
    public: true
  },
  {
    id: "links",
    title: "Learning Links",
    path: "/xcl/links",
    icon: 'icon-chain-wire icons-menu',
    cName: "nav-text",
    public: true
  },
  {
    id: "adminfolder",
    title: "Admin",
    icon: 'icon-gear-wire icons-menu folder',
    cName: "nav-folder",
    public: false,
    children: [
      {
        id: "adminsettings",
        title: "Admin Settings",
        path: "/xcl/adminsettings",
        cName: "nav-text-child",
        public: false
      },
      {
        id: "accounts",
        title: "Accounts",
        path: "/xcl/accounts",
        cName: "nav-text-child",
        public: false
      }
    ]
  },
  {
    id: "lrs",
    title: "Analytics",
    icon: 'icon-analytics-wire icons-menu folder',
    cName: "nav-folder",
    public: true,
    children: [
      {
        id: "lrsDash",
        title: "Dashboard",
        path: "/xcl/lrsdashboard",
        cName: "nav-text-child",
        public: true
      },
      {
        id: "lrsActivity",
        title: "Activity Stream",
        path: "/xcl/lrsactivity",
        cName: "nav-text-child",
        public: true
      }
    ]
  },
  {
    id: "help",
    title: "Help & Support",
    path: "/xcl/help",
    icon: 'icon-help-wire icons-menu',
    cName: "nav-text",
    public: true
  },
  {
    id: "accessibility",
    title: "Accessibility Statement",
    path: "/xcl/accessibilitystatement",
    icon: 'icon-clipboard-wire icons-menu',
    cName: "nav-text",
    public: true
  },
  {
    id: "legalfolder",
    title: "Legal",
    icon: 'icon-badge-wire icons-menu folder',
    cName: "nav-folder",
    public: true,
    children: [
      {
        id: "privacypolicy",
        title: "Privacy Policy",
        path: "/xcl/privacypolicy",
        cName: "nav-text-child",
        public: true
      },
      {
        id: "terms",
        title: "Terms of Use",
        path: "/xcl/terms",
        cName: "nav-text-child",
        public: true
      }
    ]
  }
];
