import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from "react-router-dom";

const InfoPopup = (props) => {
    
    return (
        <Popup open={props.open} closeOnDocumentClick onClose={props.onClose} className='xclPop'>
            <div className='modalContent information'>
                <h2>Content Type</h2>
                <p>In order for XCL to use the correct player for your content, we need to know what type of content you're linking to.</p>
                <p>Some types aren't available here.</p>
                <ul>
                    <li style={{ color: "#64748B" }}>Downloadable documents aren't accessible from a link in XCL, but you can always upload them.</li>
                    <li style={{ color: "#64748B" }}>PDF files AND SCORM 2004 courses won't be able to play in our player to gather rich data unless they 
                        are hosted with us. However, you can always use a link to a PDF along with the type Website and your PDF will play in the browser's 
                        default viewer. However, you'll only get launch data in that case.</li>
                </ul>
                <p><strong>Note</strong>: for xAPI courses, the URL entered must lead to the unzipped module's launch file.</p>
            </div>            
            <div className='controls'>
                <Link className='cancelBtn' onClick={props.onClose}>OK</Link>
            </div>
        </Popup>
    );


}

export default InfoPopup;