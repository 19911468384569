import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from "react-router-dom";

const InfoPopup = (props) => {
    
    return (
        <Popup open={props.open} closeOnDocumentClick onClose={props.onClose} className='xclPop'>
            <div className='modalContent information'>
                <h2>LRS Details</h2>
                <p>In order to track content in xAPI you'll need to let us know where to send your data. Please enter your LRS 
                    endpoint and credentials here. You can get a free LRS account with Veracity Learning's Cloud Hosted SaaS LRS &nbsp;
                    <a href="https://lrs.io/ui/users/create/?referrer=buildXCL" target="_blank" rel="noreferrer">here</a>.</p>
                <p>Once you have an LRS set up, create a user account (or "Access Key") with read and write permission and provide us the 
                    information in the form.</p>
                <p>If you've used your preferred LRS details in another brand template, you can chose to copy those settings here.</p>
            </div>
            <div className='controls'>
                <Link className='cancelBtn' onClick={props.onClose}>OK</Link>
            </div>
        </Popup>
    );


}

export default InfoPopup;