import mobileBg from '../img/mobile-container.png';
import livepreviewStyle from '../xcl_modules/livepreviewStyle';

const LivePreview = (props) => {

    const lpMode = props.lpMode;
    const profileFields = props.profileFields;
    const inputs = props.inputs;
    const systemSettings = props.systemSettings;
    const topMargin = (props.topMargin) ? props.topMargin : '58px';

    return (
        <>
            <style>{livepreviewStyle}</style>
            <div className={(lpMode === "web") ? "lpContainer" : "lpContainer lpHide"} style={(inputs.brand_bg_color) ? {backgroundColor: inputs.brand_bg_color, margin: `${topMargin} 0px 0px 0px` } : { margin: `${topMargin} 0px 0px 0px` }}>
                <div className='lpTile'>
                  <div className='lpLogo' style={{ backgroundImage: `url('${inputs.brand_logo}')`}} />
                  <h1>{inputs.headline}</h1>
                  <h2 style={ (inputs.isRandom) ? { textAlign: "center" } : {} }>{inputs.instructionaltext}</h2>
                  <div style={ inputs.isRandom===0 ? { display: "block" } : { display: "none"} }>
                    <label htmlFor="preview_field1" style={ profileFields[0].visible===1 ? { display: "block" } : { display: "none"} }>{(typeof(profileFields[0])==="undefined") ? "label" : profileFields[0].label + (profileFields[0].required ? " " + systemSettings.mandatory_symbol : "" )}</label>
                    <input type="text"
                        name="preview_field1" 
                        style={ profileFields[0].visible===1 ? { display: "block" } : { display: "none"} }
                        placeholder={ profileFields[0].placeholder || '' }></input>
                    <label htmlFor="preview_field2" style={ profileFields[1].visible===1 ? { display: "block" } : { display: "none"} }>{(typeof(profileFields[1])==="undefined") ? "label" : profileFields[1].label + (profileFields[1].required ? " " + systemSettings.mandatory_symbol : "" )}</label>
                    <input type="text"
                        name="preview_field2" 
                        style={ profileFields[1].visible===1 ? { display: "block" } : { display: "none"} }
                        placeholder={ profileFields[1].placeholder || '' }></input>
                    <label htmlFor="preview_field3" style={ profileFields[2].visible===1 ? { display: "block" } : { display: "none"} }>{(typeof(profileFields[2])==="undefined") ? "label" : profileFields[2].label + (profileFields[2].required ? " " + systemSettings.mandatory_symbol : "" )}</label>
                    <input type="text"
                        name="preview_field3" 
                        style={ profileFields[2].visible===1 ? { display: "block" } : { display: "none"} }
                        placeholder={ profileFields[2].placeholder || '' }></input>
                    <label htmlFor="preview_field4" style={ profileFields[3].visible===1 ? { display: "block" } : { display: "none"} }>{(typeof(profileFields[3])==="undefined") ? "label" : profileFields[3].label + (profileFields[3].required ? " " + systemSettings.mandatory_symbol : "" )}</label>
                    <input type="text"
                        name="preview_field4" 
                        style={ profileFields[3].visible===1 ? { display: "block" } : { display: "none"} }
                        placeholder={ profileFields[3].placeholder || '' }></input>
                  </div>
                  <input type="button" className='previewSubmit' value={inputs.submitbuttonlabel || systemSettings.submitbuttonlabel || ""}></input>
                  <div style={ inputs.isRandom===0 ? { display: "block" } : { display: "none"} }>
                    <input type="button" value={systemSettings.clearbuttonlabel || ""}></input>
                  </div> 
                </div>
            </div>
            <div className={(lpMode === "web") ? "lpMobileContainer lpHide" : "lpMobileContainer"} style={(lpMode === "web") ? { margin: `${topMargin} auto 0px` } : { margin: `${topMargin} auto 0px`, backgroundImage: `url('${mobileBg}')` } }>
                <div className='lpContainer mobile' style={(inputs.brand_bg_color) ? { backgroundColor: inputs.brand_bg_color } : { }}>
                <div className='lpTile mobile'>
                  <div className='lpLogo' style={{ backgroundImage: `url('${inputs.brand_logo}')`}} />
                    <h1>{inputs.headline}</h1>
                    <h2 style={ (inputs.isRandom) ? { textAlign: "center" } : {} }>{inputs.instructionaltext}</h2>
                    <div style={ inputs.isRandom===0 ? { display: "block" } : { display: "none"} }>
                    <label htmlFor="preview_field1_mobile" style={ profileFields[0].visible===1 ? { display: "block" } : { display: "none"} }>{(typeof(profileFields[0])==="undefined") ? "no" : profileFields[0].label + (profileFields[0].required ? " " + systemSettings.mandatory_symbol : "" )}</label>
                    <input type="text"
                        name="preview_field1_mobile" 
                        style={ profileFields[0].visible===1 ? { display: "block" } : { display: "none"} }
                        placeholder={ profileFields[0].placeholder || '' }></input>
                    <label htmlFor="preview_field2_mobile" style={ profileFields[1].visible===1 ? { display: "block" } : { display: "none"} }>{(typeof(profileFields[1])==="undefined") ? "no" : profileFields[1].label + (profileFields[1].required ? " " + systemSettings.mandatory_symbol : "" )}</label>
                    <input type="text"
                        name="preview_field2_mobile" 
                        style={ profileFields[1].visible===1 ? { display: "block" } : { display: "none"} }
                        placeholder={ profileFields[1].placeholder || '' }></input>
                    <label htmlFor="preview_field3_mobile" style={ profileFields[2].visible===1 ? { display: "block" } : { display: "none"} }>{(typeof(profileFields[2])==="undefined") ? "no" : profileFields[2].label + (profileFields[2].required ? " " + systemSettings.mandatory_symbol : "" )}</label>
                    <input type="text"
                        name="preview_field3_mobile" 
                        style={ profileFields[2].visible===1 ? { display: "block" } : { display: "none"} }
                        placeholder={ profileFields[2].placeholder || '' }></input>
                    <label htmlFor="preview_field4_mobile" style={ profileFields[3].visible===1 ? { display: "block" } : { display: "none"} }>{(typeof(profileFields[3])==="undefined") ? "no" : profileFields[3].label + (profileFields[3].required ? " " + systemSettings.mandatory_symbol : "" )}</label>
                    <input type="text"
                        name="preview_field3_mobile" 
                        style={ profileFields[3].visible===1 ? { display: "block" } : { display: "none"} }
                        placeholder={ profileFields[3].placeholder || '' }></input>
                  </div>
                  <input type="button" className='previewSubmit' value={systemSettings.submitbuttonlabel || ""}></input>
                  <div style={ inputs.isRandom===0 ? { display: "block" } : { display: "none"} }>
                    <input type="button" value={systemSettings.clearbuttonlabel || ""}></input>
                  </div>
                </div>
                </div>
            </div>
        </>
    )

}

export default LivePreview;