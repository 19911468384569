import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from "react-router-dom";

const InfoPopup = (props) => {
    
    return (
        <Popup open={props.open} closeOnDocumentClick onClose={props.onClose} className='xclPop'>
            <div className='modalContent information'>
                <h2>Anonymous Access</h2>
                <p>When using this option, each new user will be assigned a random ID when they first use a content link. XCL will 
                    attempt to maintain that ID for all experience data related to that content from that user. You will 
                    not collect Personal Identifiable Information (PII) from your users, but will have some visibility on unique 
                    users of your content. This option removes all fields from the landing page lowering the barrier of 
                    access to the content.</p>
            </div>            
            <div className='controls'>
                <Link className='cancelBtn' onClick={props.onClose}>OK</Link>
            </div>
        </Popup>
    );


}

export default InfoPopup;