import logo from '../img/xcl_logo_dark.svg';
import Profiles from '../components/Loginprofiles';
import { useNavigate } from 'react-router-dom';

const logoAlt = "Build Capable XCL Content Links Logo";

const rand = Math.random() * Profiles.length;
let thisprofileN = Math.floor(rand);

const ThankYou = () => {

    const nav = useNavigate();

    const doSubmit = () => {
        nav("/login");
    }

    return (
        <>
            <img className="logo" src={logo} alt={logoAlt} />
            <div title="Patent Statement" className='patentMessage login'>{process.env.REACT_APP_PATENT_MESSAGE}<br/><span style={{ fontSize: "0.75rem" }}>{process.env.REACT_APP_PATENT_NUMBER}</span></div>
            <form onSubmit={doSubmit} className='loginForm'>
                <h1 className='login'>Success!<br/>You're subscribed to Build Capable XCL.</h1>
                <h2 style={{ margin:"24px 0px", fontSize: "1.2rem", lineHeight: "130%", fontWeight: "500" }}>
                    If this is a new account, check your email for a link to set your password.
                    <br/><br/>
                    If you made changes to an existing subscription, you can log in with your existing password.
                </h2>
                <input type="submit" value="Log in" name="dologin"></input>
            </form>
            <div className="quotePanel">
                <img className="profilepic" src={Profiles[thisprofileN].img} alt={Profiles[thisprofileN].name} />
                <div className="quote">
                    “{Profiles[thisprofileN].tag}”
                </div>
                <div className="byline">
                    {Profiles[thisprofileN].name}
                </div>
            </div>
            <div className="quotePanel">
                <img className="profilepic" src={Profiles[thisprofileN].img} alt={Profiles[thisprofileN].name} />
                <div className="quote">
                    “{Profiles[thisprofileN].tag}”
                </div>
                <div className="byline">
                    {Profiles[thisprofileN].name}
                </div>
            </div>
        </>

    );
};

export default ThankYou;