
const XCL = () => {

    return (
        <>
            <div style={{ maxWidth: "1200px", margin: "48px auto 12px auto", backgroundColor: "#ECECEC", padding: "24px" }}>
                <style>
                    { (`
                        .plan {
                            width: 27%;
                            padding: 14px;
                        }
                        `)}
                </style>
                <div style={{ margin: "12px 0px", display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                    <div className="plan" style={{ backgroundColor: "#f1f5f9" }}>
                        <h1>BETA Program</h1>
                        <h3>For developers who would like to test out the pre-release version of Build Capable XCL.</h3>
                        <a href="https://buildxcl-test.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Trial-USD-Weekly&utm_source=cb-app-copy">Free 14 days</a>
                    </div>
                    
                </div>
                <div style={{ margin: "24px 44px" }}>
                    Already have an account? <a href="/login">Login</a>
                </div>
            </div>
        </>
    );
};

export default XCL;
  