import ScrollToTop from '../components/ScrollToTop';
import validate from '../xcl_modules/validate';
import { useState, useEffect } from 'react';
import Header from "../components/Header";
import createStyles from '../xcl_modules/createStyles';
import { Link, useNavigate } from "react-router-dom";
import saveStepIcon from '../css/icons/icon-arrowright-drk.png';
import prevStepIcon from '../css/icons/icon-arrowleft.png';
import getTokenData from '../xcl_modules/tokenData';
import iconCongrats from '../css/icons/icon-congrats-purple.png';
import ConfettiExplosion  from 'react-confetti-explosion';
import Swal from 'sweetalert2';

const iconCongratsAlt = "Congratulations icon";

const largeProps = {
    force: 0.8,
    duration: 3000,
    particleCount: 300,
    particleSize: 22,
    width: 1600,
    colors: ['#00BBF9', '#9B5DE5', '#00F5D4', '#FEE440', '#F15BB5'],
};

const LinkPublish = () => {

    //const [inputs, setInputs] = useState( { name: "" } );
    const [linkHref, setlinkHref] = useState( "" );
    const [copyMsg, setCopyMsg] = useState( "Copy Link" );
    const [published, setPublished] = useState( 0 );
    const [preview, setPreview] = useState( 0 );
    const [linkID, setLinkID] = useState( '' );
    const [accountID, setAccountID] = useState( '' );
    const [confettiOff, setConfettiOff] = useState(false);

    const [isLargeExploding, setIsLargeExploding] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        validate();
        // get data
        // local storage key is "editLinkID"
        const getData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_BE+`/link/${localStorage.getItem("editLinkID")}`, {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json', 
                        Authorization: `Bearer ${getTokenData().accessToken}`}
                });

                const result = await response.json();

                if (result.status === "success") {

                    // build the link
                    setLinkID(result.data[0].linkID);
                    setAccountID(result.data[0].accountID);
                    setConfettiOff(result.data[0].confettioff);

                    setlinkHref(`${process.env.REACT_APP_LINK_HOST}/?m=${result.data[0].accountID}&n=${result.data[0].linkID}`);
                    if (result.data[0].published === 1) {
                        setPreview( 0 );
                        setPublished( 1 );
                    } else {
                        setPreview( 1 );
                    }
        
                }
                
            } catch {
                Swal.fire({
                    position: "top",
                    iconHtml:  '<div class="positoast"></div>',
                    title: "System Error :O  Please Login again.",
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true,
                    timerProgressBar: true,
                    showClass: {
                        popup: 'toastCongratsOn'
                    },
                    hideClass: {
                        popup: 'toastCongratsOff'
                    }
                });
            }
        }

        getData();
    }, []);

    const handlePublish = async () => {
        
        setIsLargeExploding(true);
        setPublished( 1 );
        setPreview( 0 );
        try {
            await fetch(process.env.REACT_APP_BE+`/linkpublish`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 
                    Authorization: `Bearer ${getTokenData().accessToken}`},
                body: JSON.stringify( { ID: localStorage.getItem("editLinkID"), published: 1 } )
            });
            Swal.fire({
                position: "top",
                iconHtml:  '<div class="positoast"></div>',
                title: "Your Link has been published successfully.",
                showConfirmButton: false,
                timer: 3500,
                toast: true,
                timerProgressBar: true,
                showClass: {
                    popup: 'toastCongratsOn'
                },
                hideClass: {
                    popup: 'toastCongratsOff'
                }
            });
        } catch {
            Swal.fire({
                position: "top",
                iconHtml:  '<div class="positoast"></div>',
                title: "System Error :O  Please Login again.",
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                timerProgressBar: true,
                showClass: {
                    popup: 'toastCongratsOn'
                },
                hideClass: {
                    popup: 'toastCongratsOff'
                }
            });
        }
        
    }

    const handleUnpublish = async () => {
        
        setPublished( 0 );
        setPreview( 1 );
        try {
            await fetch(process.env.REACT_APP_BE+`/linkpublish`, {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json', 
                    Authorization: `Bearer ${getTokenData().accessToken}`},
                body: JSON.stringify( { linkID: linkID, accountID: accountID } )
            });
            Swal.fire({
                position: "top",
                iconHtml:  '<div class="positoast"></div>',
                title: "Your Link has been unpublished successfully.",
                showConfirmButton: false,
                timer: 3500,
                toast: true,
                timerProgressBar: true,
                showClass: {
                    popup: 'toastCongratsOn'
                },
                hideClass: {
                    popup: 'toastCongratsOff'
                }
            });
        } catch {
            Swal.fire({
                position: "top",
                iconHtml:  '<div class="positoast"></div>',
                title: "System Error :O  Please Login again.",
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                timerProgressBar: true,
                showClass: {
                    popup: 'toastCongratsOn'
                },
                hideClass: {
                    popup: 'toastCongratsOff'
                }
            });
        }
        
    }

    const handlePrevNavigation = (event) => {
        event.preventDefault();
        navigate("/linkcontent");
    }
  
    const handleSubmit = (event) => {
        event.preventDefault();
        navigate("/xcl/links");
    }

    const progress = "100%";

    const doLinkCopy = () => {
        navigator.clipboard.writeText(linkHref.toString()).then(() => {
            setCopyMsg("Copied!");
        }).catch(() => {
            setCopyMsg("Try again");
        });
    }

    const handleCancel = () => {
        navigate("/xcl/links");
    }
  
    return (
        <>
        <Header/>
        <ScrollToTop/>
        <style>{createStyles}</style>
        <div className='createnew header'>
            <div className='stepperheader'>
                <Link to="/xcl/links">&lt; Back to Dashboard</Link>
                <h3>
                Learning Link 
                </h3>
                <p>
                Make updates and edits to your learning link, when saved they will automatically update the learning link. 
                </p>
                <div className='steps'>
                  <div className='step prior'>
                      <Link to="/linkname">
                      <div className='indicator' title="step 1">&#10003;</div>
                      Name and Brand Details
                      </Link>
                  </div>
                  <hr/>
                  <div className='step prior'>
                      <Link to="/linkcontent">
                      <div className='indicator' title="step 2">&#10003;</div>
                      Learning Link Content
                      </Link>
                  </div>
                  <hr/>
                  <div className='step links active'>
                      <Link>
                      <div className='indicator' title="step 3">3</div>
                      Publish Link
                      </Link>
                  </div>
                </div>
            </div>
        </div>
        <form onSubmit={handleSubmit}>

          <div className='formContainer' style={(published) ? { display: "block" } : { display: "none" } }>
            <img src={iconCongrats} alt={iconCongratsAlt} style={{ display: "block", width: "60px", margin: "0px auto" }} />
            {(confettiOff) ? (<></>) : (
                <div style={{ display: "block", height: "1px", width: "1px", margin: "0px auto" }} published={published}>
                    {isLargeExploding && <ConfettiExplosion {...largeProps} />}
                </div>
            )}
            <h4 id="contentstarts" style={{ textAlign: "center" }}>Congratulations!</h4>
            <h5 style={{ textAlign: "center" }}>Your learning link has been published and is now live online.</h5>
            
            <input type="text" name="linktext" disabled value={linkHref} style={{ width: "calc(100% - 180px)", marginRight: "16px", height: "48px" }} />
            <input type="button" value={copyMsg} onClick={doLinkCopy} name="copybtn" style={{ height: "48px", fontSize: "1rem" }} ></input>

            <div style={{ textAlign: "center" }}>
                <Link className='linkpreview' to={`${linkHref}&preview=1`} target='_blank'>Preview Link</Link>
                <Link className='linkpreview' to={linkHref} target='_blank'>Open Link</Link>
            </div>
            <div style={{ textAlign: "center" }}>
                <Link className='linkunpublish' onClick={handleUnpublish} style={(published) ? {display:"inline-block"} : { display:"none"} }>Unpublish Link</Link>
            </div>

            
          </div>

          <div className='formContainer' style={(preview) ? { display: "block" } : { display: "none" } }>
            <h4 id="contentstarts" style={{ textAlign: "center" }}>Preview</h4>
            <h5 style={{ display: "block", textAlign: "center" }}>Your learning link is ready to be previewed. Take a moment to check that your content launches 
                properly. All statements sent from the preview will be sent as "Preview" so you can filter them out later if you choose.</h5>
            <div style={{ textAlign: "center" }}>
            <label htmlFor='withStatementViewer' style={{ marginTop: "0px", display: "none", width: "320px", verticalAlign: "super" }}>Include a statement preview popup window?</label>
                <input name="withStatementViewer" type="checkbox" style={{ display: "none", marginTop: "0px" }}></input>
            </div>
            <div style={{ textAlign: "center" }}>
                <Link className='linkpreview' to={`${linkHref}&preview=1`} target='_blank'>Preview Learning Link</Link>
                <Link className='linkpublish' onClick={handlePublish} style={{display:"inline-block"}}>Publish Learning Link</Link>
            </div>

        </div>

        <div className='formFooter'>
            <div className='progressbarbackground'>
                <div className='progressbarprogress links' style={{ width: progress }}></div>
            </div>
            <div className='stepperFormControls'>
                <input type="button" name='backone' value="Previous: Link Content" style={{ backgroundImage: `url('${prevStepIcon}')`}} onClick={handlePrevNavigation}></input>
                <input type="button" name='cancel' className='cancel' value="Cancel" style={{}} onClick={handleCancel}></input>
                <input type="submit" name='subitBtn' value="Exit" style={{ backgroundImage: `url('${saveStepIcon}')`}}></input>
            </div>
        </div>
      </form>
      <div className='end'> </div>
    </>
  );
};

export default LinkPublish;