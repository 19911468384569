import ScrollToTop from '../components/ScrollToTop';
import contactUsIcon from '../img/contactus_icon.png';
import validate from '../xcl_modules/validate';
import { useEffect } from 'react';
import { useOutletContext } from "react-router-dom";

const contactUsIconAlt = "We're here to help Icon"

const TermsAndConditions = () => {

  const [navOpen] = useOutletContext();

  useEffect(() => {
    validate();
  }, []);

  return (
    <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
      <ScrollToTop/>
      <h1 id="contentstarts">
        Privacy Policy
      </h1>
      <h4>
      Your privacy is important to us. This policy outlines how we collect, use, and protect your personal information.
      </h4>
      <div className="helpPageTile">

        <h3>Privacy Policy</h3>
        <p>This Privacy Policy ("Policy") applies to https://www.buildxcl.com, Build Capable XCL (“Product”), and Learning Ninjas 
          LLC dba Build Capable ("Company") and governs data collection and usage. For the purposes of this Privacy Policy, 
          unless otherwise noted, all references to the Company and the Product include https://www.buildxcl.com and Build Capable XCL. 
          The Company's Product is a learning technology application. By using the Company's Product, you consent to the data practices 
          described in this statement.</p>

        <h3>Collection of your Personal Information</h3>
        <p>In order to better provide you with products and services offered, the Company may collect personally identifiable information, such as your:</p>
        <ul>
          <li>First and last name</li>
          <li>Mailing Address</li>
          <li>Email Address</li>
          <li>Phone Number</li>
          <li>Company Name</li>
          <li>Job Title</li>
        </ul>
        <p>If you purchase the Company's products and services, we collect billing and credit card information. This information is used to complete the purchase transaction.</p>
        <p>We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account;
        (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us 
        an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services. To wit, we will use your 
        information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional 
        personal or non-personal information in the future.</p>

        <h3>Use of your Personal Information</h3>
        <p>The Company collects and uses your personal information in the following ways:</p>
        <ul>
          <li>To operate and deliver the services you have requested</li>
          <li>To provide you with the information, products, or services that you request from us</li>
          <li>To provide you with notices about your account</li>
          <li>To carry out the Company's obligations and enforce our rights arising from any contracts entered between you and us, including for billing and collection</li>
          <li>To notify you about changes to the Product or any products or services we offer or provide through them</li>
          <li>In any other way we may describe when you provide the information</li>
          <li>For any other purpose with you consent</li>
        </ul>
        <p>The Company may also use your personally identifiable information to inform you of other products or services available from the Company and its affiliates.</p>

        <h3>Sharing Information with Third Parties</h3>
        <p>The Company does not sell, rent, or lease its customer lists to third parties.</p>
        <p>The Company may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer 
          support, or provide other necessary services. All such third parties are prohibited from using your personal information except to provide 
          these services to the Company, and they are required to maintain the confidentiality of your information.</p>
        <p>The Company may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action 
          is necessary to: (a) conform to the edicts of the law or comply with legal process served on the Company or the site; (b) protect and defend the 
          rights or property of the Company; and/or (c) act under exigent circumstances to protect the personal safety of users of the Company, or the public.</p>

        <h3>Security of Your Personal Information</h3>
        <p>The Company secures your personal information from unauthorized access, use, or disclosure. The Company uses the following methods for this purpose:</p>
        <ul>
          <li>Customer profile and payment information is collected and managed by ChargeBee, a third-party subscription application. The connection between the Product 
              and ChargeBee is encrypted.</li>
          <li>Customers may elect to use Veracity LRS, an external learning record store, to collect learner activity data. The connection between the Product and 
              Veracity LRS is encrypted.</li>
        </ul>
        <p>We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, 
          no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal 
          information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet that are beyond our control; and (b) the security, 
          integrity, and privacy of any and all information and data exchanged between you and us through this site cannot be guaranteed.</p>
      
        <h3>Right to Deletion</h3>
        <p>Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:</p>
        <ul>
          <li>Delete your personal information from our records; and</li>
          <li>Direct any service providers to delete your personal information from their records.</li>
        </ul>
        <p>Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:</p>
        <ul>
          <li>Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted 
              in accordance with federal law, and provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business 
              relationship with you, or otherwise perform a contract between you and us</li>
          <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;</li>
          <li>Debug to identify and repair errors that impair existing intended functionality;</li>
          <li>Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;</li>
          <li>Comply with the California Electronic Communications Privacy Act;</li>
          <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and 
              privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have 
              obtained your informed consent;</li>
          <li>Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;</li>
          <li>Comply with an existing legal obligation; or</li>
          <li>Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.</li>
        </ul>

        <h3>Children Under Sixteen</h3>
        <p>The Company does not knowingly collect personally identifiable information from children under the age of 16. If you are under the age of 16, you are not 
          permitted to use this application. If you are distributing content to individuals under the age of 16, you may not use Build Capable XCL to collect 
          personally identifiable information from these individuals.</p>
        
        <h3>Email Communications</h3>
        <p>By creating an account, you agree that you may receive communication from the Company, such as newsletters, announcements, promotional offers, 
          alerts, confirmations, account reminders, account updates, surveys, and/or other general communication. In order to improve our services, we may receive a 
          notification when you open an email from the Company or click on a link therein.</p>
        <p>If you would like to stop receiving marketing or promotional communications via email from the Company, you may opt out of such communications by selecting 
          the “Unsubscribe” link in the footer of the actual email or contacting <a href={`mailto:${process.env.REACT_APP_CONTACTEMAIL}`}>{process.env.REACT_APP_CONTACTEMAIL}</a>. You cannot opt out of 
          essential communications regarding your 
          account, such as renewal notices.</p>
        
        <h3>External Data Storage Sites</h3>
        <p>We may store your data on servers provided by third-party vendors with whom we have contracted.</p>

        <h3>Changes to This Statement</h3>
        <p>The Company reserves the right to change this Policy from time to time. For example, when there are changes in our services, changes in our data protection 
          practices, or changes in the law. When changes to this Policy are significant, we will inform you. You may receive a notice by sending an email to the primary 
          email address specified in your account, by placing a prominent notice on the Product, and/or by updating any privacy information. Your continued use of the 
          application and/or services available after such modifications will constitute your: (a) acknowledgment of the modified Policy; and (b) agreement to abide and 
          be bound by that Policy.</p>

        <h3>Contact Information</h3>
        <p>The Company welcomes your questions or comments regarding this Policy. If you believe that the Company has not adhered to this Policy, please contact the Company at:
          <br/><br/>
          Build Capable<br/>
          5900 Balcones Drive STE 13207<br/>
          Austin, Texas 78731<br/>
          <br/>
          Email Address:<br/>
          <a href={`mailto:${process.env.REACT_APP_CONTACTEMAIL}`}>{process.env.REACT_APP_CONTACTEMAIL}</a><br/>
          <br/>
          Phone Number:<br/>
          +1-844-844-1595<br/>
        </p>

      </div>
      <div className="helpPageTile">
        <div className="titleBlock">
          <h2>
            Let us know if you have questions or concerns about privacy.<br/><span className='titleEmphasis'>  
            We're here to help.</span>
          </h2>
          <img src={contactUsIcon} alt={contactUsIconAlt} />
        </div>
        <div className="titleBlock">
          <div className='contactInfo'>
            <h2>Contact Us</h2>
            <div>
              <span className='contact-icons icon-phone-fill'/>
              <strong>Phone</strong>: <a href="tel:+1 844 844 1595">+1 844 844 1595</a>
            </div>
            <div>
              <span className='contact-icons icon-email-fill'/>
              <strong>Email</strong>: <a href={`mailto:${process.env.REACT_APP_CONTACTEMAIL}`}>{process.env.REACT_APP_CONTACTEMAIL}</a>
            </div>
            <div>
              <span className='contact-icons icon-pin-fill' />
              <strong>Address</strong>: 5900 Balcones Drive Suite 13207 Austin, TX 78731
            </div>
            <div>
              <span className='contact-icons icon-linkedin-fill'/>
              <a href="https://www.linkedin.com/company/buildcapable/" target='_blank' rel="noreferrer">Build Capable on LinkedIn</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;