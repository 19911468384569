import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import { useState } from 'react';
import Onboarding from '../components/Onboarding';

const Layout = () => {
  const [navOpen, setNavOpen] = useState(true);

  const doNavChange = (isOpen) => {
    setNavOpen( (isOpen) ? true : false );
  }

  return (
    <>
      <Header/>
      <Navbar doNavChange={doNavChange} />
      <Outlet context={ [navOpen] } />
      <Onboarding />
    </>
  )
};

export default Layout;
