import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from "react-router-dom";

const InfoPopup = (props) => {
    
    return (
        <Popup open={props.open} closeOnDocumentClick onClose={props.onClose} className='xclPop'>
            <div className='modalContent information'>
                <h2>Anonymous User Email Domain</h2>
                <p>This is the email domain that will be used to craft the mbox of all Anonymous Users in xAPI statements.</p>
                <p>The full email identifier will use the randomly generated id along with this email domain to create the mbox value.</p>
                <p>Example mbox value:<br/>7f77215e-557a-4466-9dd9-f8744f6204d0<strong>@thisdomain.com</strong></p>
            </div>            
            <div className='controls'>
                <Link className='cancelBtn' onClick={props.onClose}>OK</Link>
            </div>
        </Popup>
    );


}

export default InfoPopup;