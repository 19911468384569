
const NoPage = () => {

  return (
      <>
        
      </>);
  };
  
  export default NoPage;
  