import ScrollToTop from '../components/ScrollToTop';
import validate from '../xcl_modules/validate';
import { useEffect, useState  } from 'react';
import { useOutletContext } from "react-router-dom";
import imgSrc from '../css/icons/icon-analytics.png';
import getUserData from '../xcl_modules/userData';
import Swal from 'sweetalert2';
import getTokenData from '../xcl_modules/tokenData';

const imgAlt = "Analytics graph icon";

const LRSactivity = () => {

  const [navOpen] = useOutletContext();
  const [links, setLinks] = useState( [] );

  const userData = getUserData();
  const endpoint = userData.xcl_lrs_endpoint;
  const auth = "Basic " + btoa(userData.xcl_lrs_username + ":" + userData.xcl_lrs_password);
  
  useEffect(() => {
    validate();
    const getData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_BE+'/linklist', {
          method: 'GET',
          headers: {'Content-Type': 'application/json', 
              Authorization: `Bearer ${getTokenData().accessToken}`}
        });
        const result = await response.json();
        if (result.code === 202) {
          // there are no links :(
          result.list = [ { ID:0, listEmpty: "message" } ];
        } else {
          const linkList = [];
          result.list.forEach((linkItem) => {
            linkList.push(JSON.stringify({ id: linkItem.linkID, name: linkItem.course_title }));
          });

          setLinks(linkList);
        }
      } catch {
        Swal.fire({
          position: "top",
          iconHtml:  '<div class="positoast"></div>',
          title: "System Error :O  Please Login again.",
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          timerProgressBar: true,
          showClass: {
            popup: 'toastCongratsOn'
          },
          hideClass: {
            popup: 'toastCongratsOff'
          }
        });
      }
    }
    getData();
  }, []);

  return (
    <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
      <ScrollToTop/>
      <h1 id="contentstarts">
        <img src={imgSrc} alt={imgAlt} className='iconInline' style={{ verticalAlign: "middle" }} />
        Activity Stream 
      </h1>
      <h4>Displays records from your internal XCL LRS.</h4>
      <div style={{ marginTop: "16px" }}>
        { (links==='') ? (
          <>There is no data stored quite yet.</>
        ) : (
          <iframe title="xAPI Statement Viewer" style={{ width: "100%", borderRadius: "12px", boxShadow: "0px 1px 3px 0px #0000001A", border: "none", height: "2600px" }} src={`${process.env.REACT_APP_LINK_HOST}/statements/?endpoint=${endpoint}&auth=${auth}&links=${links}`}></iframe>
        )}
      </div>
    </div>
  );
};

export default LRSactivity;