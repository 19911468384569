import './css/App.css';
import './css/Resp.css';
import './css/Icons.css';
import "./css/Navbar.css";
import './css/Help.css';
import './css/Login.css';
import './css/Home.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import XCL from "./routes/XCL";
import Thankyou from "./routes/Thankyou";

import Layout from "./routes/Layout";
import Login from "./routes/Login";
import Logout from "./routes/Logout";
import ForgotPassword from "./routes/ForgotPassword";
import ResetPassword from "./routes/ResetPassword";
import Home from "./routes/Home";
import Help from "./routes/Help";
import AdminSettings from "./routes/AdminSettings";
import AdminAccounts from "./routes/AdminAccounts";
import AccessibilityStatement from "./routes/AccessibilityStatement";

import Links from "./routes/Links";
import LinkNew from "./routes/LinkNew";
import LinkName from "./routes/LinkName";
import LinkContent from "./routes/LinkContent";
import LinkPublish from "./routes/LinkPublish";

import Profiles from "./routes/Profiles";
import ProfileNew from "./routes/ProfileNew";
import ProfileName from "./routes/ProfileName";
import ProfileLRS from "./routes/ProfileLRS";
import ProfileBranding from "./routes/ProfileBranding";
import ProfileCustomize from "./routes/ProfileCustomize";

import Account from "./routes/Account";
import NoPage from "./routes/NoPage";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import TermsAndConditions from "./routes/TermsAndConditions";

import LRSdashboard from "./routes/LRSdashboard";
import LRSactivity from "./routes/LRSactivity";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        
        <Route index element={<Navigate to="/login" />} />
        <Route path="/plans" element={<XCL />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
      
        <Route path="/xcl" element={<Layout />}>
          <Route path="" element={<Home />} />
          <Route path="help" element={<Help />} />
          <Route path="adminsettings" element={<AdminSettings />} />
          <Route path="accounts" element={<AdminAccounts />} />
          <Route path="accessibilitystatement" element={<AccessibilityStatement />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<TermsAndConditions />} />
          <Route path="links" element={<Links />} />
          <Route path="profiles" element={<Profiles />} />
          <Route path="account" element={<Account />} />
          <Route path="lrsdashboard" element={<LRSdashboard />} />
          <Route path="lrsactivity" element={<LRSactivity />} />
          <Route path="*" element={<NoPage />} />
        </Route>

        <Route path="/profilenew" element={<ProfileNew />} />
        <Route path="/profilename" element={<ProfileName />} />
        <Route path="/profilelrs" element={<ProfileLRS />} />
        <Route path="/profilebranding" element={<ProfileBranding />} />
        <Route path="/profilecustomize" element={<ProfileCustomize />} />
        
        <Route path="/linknew" element={<LinkNew />} />
        <Route path="/linkname" element={<LinkName />} />
        <Route path="/linkcontent" element={<LinkContent />} />
        <Route path="/linkpublish" element={<LinkPublish />} />

        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
