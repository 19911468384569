import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from "react-router-dom";

const InfoPopup = (props) => {
    
    return (
        <Popup open={props.open} closeOnDocumentClick onClose={props.onClose} className='xclPop'>
            <div className='modalContent information'>
                <h2>Anonymous User</h2>
                <p>This is the "name" used to identify all Anonymous Users in xAPI statements.</p>
            </div>            
            <div className='controls'>
                <Link className='cancelBtn' onClick={props.onClose}>OK</Link>
            </div>
        </Popup>
    );


}

export default InfoPopup;