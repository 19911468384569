const LengthIndicator = (props) => {

    const length = parseInt(props.length);
    const max = parseInt(props.max);
    const threshold = parseFloat(props.threshold);
    const plen = (length/max);

    return (
        <>
            {(plen > threshold) ? (
                <div className={(plen > threshold) ? "lengthIndicator alert" : "lengthIndicator" }>
                    {(length === max) ? (
                        <>Max length {length}/{max}</>
                    ) : (
                        <>You're getting close to the max length for this field {length}/{max}</>
                    )}
                </div>
            ) : (
                <></>
            )}
        </>
    )

}

export default LengthIndicator;