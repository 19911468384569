import profile0 from '../img/profiles/sarah.png';
import profile1 from '../img/profiles/teresa.png';
import profile2 from '../img/profiles/rob.png';

const profiles = [
    {
        img: profile0,
        tag: "We take the P out of LXP",
        name: "Sarah Mercier, Chief Executive Officer, Instructional Strategy & Design"
    },
    {
        img: profile0,
        tag: "Powered by AI (Actual Intelligence)",
        name: "Sarah Mercier, Chief Executive Officer, Instructional Strategy & Design"
    },
    {
        img: profile1,
        tag: "Who needs LMS when you’ve got XCL",
        name: "Teresa Dussault, Chief Operations Officer, Learning Experience Design"
    },
    {
        img: profile2,
        tag: "XCL does more, so you can do less",
        name: "Rob Christie, xAPI, Application Development"
    }
];

export default profiles;