import XAPI from '@xapi/xapi';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const sOptions = {
    chart: {
        id: "basic-bar"
    },
    xaxis: {
        categories: ["launched"]
    },
    fill: {
        colors: ['#6ca7ad', '#9f7fc9']
    },
    dataLabels: {
        enabled: true,
        style: {
          colors: ["#000"]
        }
    }
}

const sSeries = [
    {
      name: "total",
      data: [0]
    }
]

function ChartCompletion(props) {
    const [options, setOptions] = useState( sOptions );
    const [series, setSeries] = useState( sSeries );
    //const [objectID, setObjectID] = useState( "noobjectid" );

    useEffect(() => {
        const endpoint = props.endpoint;
        const auth = XAPI.toBasicAuth(props.username, props.password);
        const xapi = new XAPI({
            endpoint: endpoint,
            auth: auth
        });
        const thisRegistration = props.linkid;

        //const thisObjectId = encodeURI(props.objectId);
        //setObjectID(thisObjectId);

        //const startVerbs = (props.type==="XAPI" || props.type==="SCORM")? ["attempted"] : ["launched"];
        //const completedVerbs = (props.type==="XAPI" || props.type==="SCORM")? ["completed","passed","failed"] : ["completed"];
        const startVerbs = ["attempted","started","launched"];
        const completedVerbs = ["completed","passed","failed"];
        
        const cats = (props.type==="XAPI" || props.type==="SCORM" || props.type==="VIDEO" || props.type==="MP4" || props.type==="PDF") ? ["launched", "completed"] : ["launched"];
        // update
        const updatedOptions = {
            chart: {
                id: "basic-bar"
            },
            xaxis: {
                categories: cats
            },
            fill: {
                colors: ['#6ca7ad', '#9f7fc9']
            },
            dataLabels: {
                enabled: true,
                style: {
                  colors: ["#000"]
                }
            }
        }
        setOptions(updatedOptions);

        const seriesData = [];

        // start statements
        xapi.getStatements({
            registration: thisRegistration
        }).then((result) => {

            const statements = result.data.statements;
            let startCount = 0;
            let completedCount = 0;

            statements.forEach((stmt, i) => {
                startVerbs.forEach((verb) => {
                    if (stmt.verb.id.indexOf(verb) > -1) {
                        startCount++;
                    }                        
                });
                completedVerbs.forEach((verb) => {
                    if (stmt.verb.id.indexOf(verb) > -1) {
                        completedCount++;
                    }                        
                });
            });

            seriesData[0] = startCount;

            if (props.type==="XAPI" || props.type==="SCORM" || props.type==="VIDEO" || props.type==="MP4" || props.type==="PDF") {
                seriesData[1]  = completedCount;
            }

            const updatedSeries = [
                {
                    name: "total",
                    data: seriesData,
                }
            ];
            setSeries(updatedSeries);

        });

    },[props]);

    return (
        <div className="app">
        <div className="row">
          <div className="mixed-chart">
            
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    width="260"
                />
            
          </div>
        </div>
      </div>
    );
}

export default ChartCompletion;
