import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from '../img/xcl-logo.svg';
import iconMyaccount from '../css/icons/icon-myaccount.png';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import dropdownstyles from '../xcl_modules/dropdownstyle';

const logoAlt = "Build Capable XCL Content Links Logo";
const iconMyaccountAlt = "My Account icon";

const Header = () => {
    const [currSel, setSelection]   = useState("");

    const location = useLocation();
  
    useEffect(() => {
      setSelection(location.pathname);
    }, [location]);
  
    const clearEdits = () => {
        const ls = localStorage;
        ls.setItem("editProfileID","");
        ls.setItem("editLinkID","");
    }

    const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(2);

    const go = () => {
        setIsOpen(false);
    }

    return (
        <header>
            <style>{dropdownstyles}</style>
            <a href="#contentstarts" className="skipnavigaton">Skip to main content</a>
            <Link to="/xcl/" onClick={clearEdits} className="headerHomeLink">
                <img src={logo} alt={logoAlt} className='xcl-logo' />
            </Link>

            <Link className={(currSel === "/xcl/help") ? "helpLink active" : "helpLink"} id='helpLinkObj' to="/xcl/help" onClick={clearEdits}>Help & Support</Link>
            <Link className={(currSel === "/xcl/account") ? "myAccountLink active" : "myAccountLink"} onClick={clearEdits} {...buttonProps}>
                <img src={iconMyaccount} alt={iconMyaccountAlt} className='myAccountLinkIcon' /> My Account
            </Link>
            <div style={{ position: "relative", zIndex: "2" }}>
                    <div className={"listMenu " + (isOpen ? 'visible' : '')} role='menu' style={{ top: "-16px", right: "33px", width: "165px" }}>
                        <Link {...itemProps[0]} to="/xcl/account" onClick={go}>Account Dashboard</Link>
                        <Link {...itemProps[1]} to="/logout" onClick={go}>Logout</Link>
                    </div>
                </div>
        </header>
    )

}

export default Header;