import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavbarData } from "./NavbarData";
import { IconContext } from "react-icons";
import adminOnly from '../xcl_modules/adminOnly';

function Navbar(props) {
  const [sidebar, setSidebar] = useState(false);
  const [currSel, setSelection] = useState("");
  const [currFolder, setFolder] = useState("");
  
  const showSidebar = () => {
    setSidebar(!sidebar);
    props.doNavChange(sidebar);
    if (!sidebar) {
      setFolder("");
    }
  }
  
  const hideSidebar = () => {
    if (window.innerWidth <= 880) {
      showSidebar();
    }
  }
  
  const location = useLocation();

  useEffect(() => {
    setSelection(location.pathname);
  }, [location]);
  
  const menuItems = [];
  const isAdmin = adminOnly();
  
  NavbarData.forEach( (obj) => {
    if (obj.public || isAdmin) {
      menuItems.push(obj);
    }
  });

  const doFolderSelect = (folder) => {
    if (currFolder===folder) {
      setFolder("");
    } else {
      setFolder(folder);
      if (sidebar) {
        showSidebar();
      }
    }
  }

  return (
    <>
      <IconContext.Provider value={{ color: "undefined" }}>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <div className="navbar">
            <div className="menu-bars">
                <Link to="#" className="menu-close" onClick={showSidebar} aria-label="close menu"></Link>
                <Link to="#" className="menu-open" onClick={showSidebar} aria-label="open menu"></Link>
            </div>
          </div>
          <ul className="nav-menu-items">
            <li className="navbar-title">
                Menu
            </li>
            {menuItems.map((item, index) => {
              let isSelected = false;
              if (item.children) {
                item.children.forEach((itm) => (itm.path===currSel) ? isSelected = true : false );
              } else {
                 if (currSel === item.path) { isSelected = true }
              }
              return (
                <li key={index} className={(isSelected ? item.cName + " active" : item.cName) + ((currFolder === item.id) ? " open" : "")}>
                  { item.path ? (
                    <Link to={item.path} onClick={() => setFolder("")}>
                      <span className={item.icon} />
                      <span>{item.title}</span>
                    </Link>
                  ) : ( 
                    <>
                      <span className={(currFolder === item.id) ? "folder-open" : ""}>
                        <Link onClick={() => doFolderSelect(item.id)}>
                          <span className={item.icon} />
                          <span>{item.title}</span>
                        </Link>
                        <ul className={(currFolder===item.id) ? "nav-menu-items children active" : "nav-menu-items children"}>
                          { item.children.map((child, i) => {                          
                            return (
                              <li key={'child_'+i} className={(currSel === child.path) ? child.cName + " active" : child.cName}>
                                <Link to={child.path} onClick={hideSidebar}>
                                  <span>{child.title}</span>
                                </Link>
                              </li>
                            )
                          })}                     
                        </ul>
                      </span>
                    </>
                  )}

                </li>
              );
            })}
          </ul>
          <div title="Patent Statement" className='patentMessage menu'>{process.env.REACT_APP_PATENT_MESSAGE}<br/><span style={{ fontSize: "0.75rem" }}>{process.env.REACT_APP_PATENT_NUMBER}</span></div>
          <div title="Version Number" className="version-number menu">Version {process.env.REACT_APP_VERSION_NUMBER}</div>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;