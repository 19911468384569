import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from "react-router-dom";
import iconModalSuccess from '../css/icons/icon-announce-success.png';

const PopupSuccess = (props) => {
    
    return (
        <Popup open={props.open} onClose={props.onClose} className='xclPop'>
            <div className='successIcon' style={{ backgroundImage: `url('${iconModalSuccess}')` }}></div>
            <div className='modalContent success'>
                <h2 className='success'>Your Account Has Been Cancelled</h2>
            </div>            
            <div className='controls'>
                <Link className='closeBtn' onClick={props.onClose}>X Close</Link>
            </div>
        </Popup>
    );


}

export default PopupSuccess;