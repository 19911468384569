const cssClip = `
.createNewBtn.profiles {
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    border-radius: 10px;
    color: #F8FAFC;
    border: none;
    background-color: #00606B;
    padding: 12px 44px 12px 18px;
    cursor: pointer;
    text-decoration:none;
    display: inline-block;
    float:right;
    background-repeat:no-repeat;
    background-position: calc(100% - 17px) 13px;
    position: relative;
    top: -46px;
} 
.createNewBtn.profiles.disabled {
    background-color:#E2E8F0 !important;
    color:#0F172A !important;
    background-size: 18px 18px;
}
.createNewBtn.profiles:focus-visible, .createNewBtn.links:focus-visible {
    outline: 2px solid yellow;
}
.createNewBtn.links {
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    border-radius: 10px;
    color: #F8FAFC;
    border: none;
    background-color: #41206C;
    padding: 12px 44px 12px 18px;
    cursor: pointer;
    text-decoration:none;
    display: inline-block;
    float:right;
    background-repeat:no-repeat;
    background-position: calc(100% - 17px) 13px;
    position: relative;
    top: -46px;
}
.createNewBtn.links.disabled {
    background-color:#E2E8F0 !important;
    color:#0F172A !important;
    background-size: 18px 18px;
}
.disabled {
    background-color:#E2E8F0 !important;
    color:#64748B !important;
}
.patentMessage.listpage {
    display: none;
    width: 104px;
    text-align: center;
    font-size: 0.875rem;
    line-height: 100%;
    font-weight: 700;
    padding: 6px 8px;
    background-color: #E8E0F3;
    color: #0F172A;
    border-radius: 6px;
    margin: 0px 0px 0px 10px;
    vertical-align: middle;
}
    `;

export default cssClip;